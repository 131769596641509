import { Component } from "react"
import '@aws-amplify/ui-react/styles.css';
import queryString from 'query-string';
import ReactDOM from 'react-dom'
import { redirectToParam, emailHeader, cssFormInputLabel, cssFormInput, cssFormInputButton, cssFormInputText, redirectReasonHeader } from './Library'
import { writeLoginTokensToSession, httpOKsuccess, writeHeaderToSession } from './Library'
import DualBallSpinner from '../img/dual-ball-1s-100px.gif'
import logo from '../img/app-icon-without-background.png';



export class Logon extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            loading: false,
            buttonDisabled: false
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSuccessLogin = () => {
        const location = useLocation();
        const { redirectTo } = queryString.parse(location.search);
        history.push(redirectTo == null ? "/" : redirectTo);
    };

    handleLogon = async (e) => {
        e.preventDefault();
        try {
            this.setState({ loading: true, buttonDisabled: true })
            const loginResponse = await fetch(process.env.REACT_APP_API_ENDPOINT + 'user/login?userName=' + this.state.email + '&password=' + encodeURIComponent(this.state.password));

            const actionRequired = loginResponse.headers.get("Action-Required");

            var succesful = (loginResponse.status == httpOKsuccess && !actionRequired);
            var messageLabel = document.getElementById("loginMessage");

            if (succesful) {

                writeLoginTokensToSession(loginResponse.headers);
                window.location.href = new URLSearchParams(window.location.search).get(redirectToParam);
                this.setState({ loading: false, buttonDisabled: false });

            } else {
                if (actionRequired == "Password Change") {
                    sessionStorage.setItem(emailHeader, this.state.email);
                    sessionStorage.setItem(redirectReasonHeader, "Password needs to be changed");
                    window.location.href = 'changepassword';
                } else if (actionRequired == "Email Address Link to Business") {
                    const emailNotLinked = "mailto:tech@receiptsandrewards.com?subject=Login%20-%20Business%20Not%20Connected%20To%20Email%20Address%20 - " + this.state.email + "&body=The%20email%20address%20of%20" + this.state.email + "%20is%20registered%20in%20Cognito,%20but%20is%20not%20registerd%20in%20the%20business%20SQL%20table.";
                    ReactDOM.render(<label className={cssFormInputLabel} >Your email address isn't linked to a business. Please <a href={emailNotLinked} >contact Receipts and Rewards</a> and we will fix it.</label>, messageLabel);
                    this.setState({ loading: false, buttonDisabled: false });
                } else {
                    ReactDOM.render(<label className={cssFormInputLabel}>Login failed. Please check your username and password.</label>, messageLabel);
                    this.setState({ loading: false, buttonDisabled: false });
                }
            }
            
        }
        catch (error) {
            console.log(error);
            const networkErrorEmail = "mailto:tech@receiptsandrewards.com?subject=Login%20-%20Network%20Error&body=A%20User%20is%20unable%20to%20logon%20to%20" + window.location.host;
            var messageLabel = document.getElementById("loginMessage");
            ReactDOM.render(<label className={cssFormInputLabel} >There appears to be a network error. Please <a href={networkErrorEmail}>contact Receipts and Rewards</a> and we will fix it.</label>, messageLabel);
            this.setState({ loading: false, buttonDisabled: false });
        }

    }

    render() {
        return (
            <>
                <header>
                    <ul className="navbar-nav flex-grow">
                    <img className="logo" src={logo} alt="Receipts and Rewards Logo" width="58px" height="58px" /><div> Receipts  and Rewards </div></ul>
                    </header>
            <div>
                    <form onSubmit={this.handleLogon} className={cssFormInput} >
                        <label className={cssFormInputLabel } >
                        Email:
                        <input type="text" className={cssFormInputText} name="email" value={this.state.email} onChange={this.handleInputChange} />
                    </label>

                        <label className={cssFormInputLabel} >
                        Password:
                        <input type="password" className={cssFormInputText} name="password" value={this.state.password} onChange={this.handleInputChange} />
                        </label>
                        <input type="submit" className={cssFormInputButton} value="Logon" disabled={this.state.buttonDisabled} />
                </form>

                <a href={process.env.REACT_APP_CLOVER_BASE_URL + '/oauth/authorize?client_id=' + process.env.REACT_APP_CLOVER_APP_ID + '&redirect_uri=' + new URLSearchParams(window.location.search).get(redirectToParam)} ><img src="https://images.crunchbase.com/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/tm6vbqowjphonsordoxc" alt="Login to Clover" />
                </a>
                {this.state.loading ? <img src={DualBallSpinner} alt="Loading" /> : ''}
                <div id="loginMessage" />
                </div>
                </>
        );
    }
}